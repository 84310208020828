<template>
<b-card :title="$t('orders')">
  <b-row>
    <b-col md="2">
      <b-button size="sm" variant="outline-secondary" title="Скинути фільтр" @click="clearFilter"><fa icon="filter" /></b-button>
    </b-col>
    <b-col md="10" class="my-1">
      <div class="text-right">
        <b-button-group>
          <router-link :to="{ name: 'orders.create' }" class="btn btn-sm btn-success">
            <fa icon="plus" />
          </router-link>
          <!--<b-button size="sm" class="mr-1" variant="danger" @click="removeItems($event)" :title="$t('delete_all')">
            <fa icon="trash" />
          </b-button>
          <b-button size="sm" class="mr-1" variant="secondary" @click="exportOrders($event)" :title="$t('export')">
            <fa icon="file-export" />
          </b-button>-->
        </b-button-group>
      </div>
    </b-col>
  </b-row>
  <b-table
    responsive
    striped
    hover
    show-empty
    sort-icon-left
    :items="items"
    :fields="fields"
    :current-page="currentPage"
    :per-page="0">
    <!--<template v-slot:head(selected)>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="customCheck1" @click="selectAll" v-model="allSelected">
        <label class="custom-control-label" for="customCheck1"></label>
      </div>
    </template>-->
    <template slot="top-row" slot-scope="{ fields }">
      <td v-for="field in fields" :key="field.key">
        <b-form-input v-if="field.typesort === 'text'" size="sm" :placeholder="field.label" @change="searchByText(field.key, $event)"></b-form-input>
        <!-- delivery -->
        <div v-if="field.key === 'delivery'" class="form-group">
          <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in deliveries" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- payment -->
        <div v-if="field.key === 'payment_type'" class="form-group" >
          <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in payments" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- payment status id -->
        <div v-if="field.key === 'payment_status_id'" class="form-group">
          <select class="form-control form-control-sm"  @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in paymentStatuses" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <!-- order status id -->
        <div v-if="field.key === 'order_status'" class="form-group">
          <select class="form-control form-control-sm" @change="searchBySelect(field.key, $event)">
            <option value="">--</option>
            <option v-for="item in orderStatuses" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </td>
    </template>
    <template v-slot:cell(total)="row">
      <span class="text-danger"><strong>{{ row.item.total | currency }}</strong></span>
      <!-- check -->
      <fa v-if="hasRole === 'admin' && row.item.checkId" icon="money-check" class="text-success pointer" @click="getCheck(row.item.checkId)"/>
    </template>
    <template v-slot:cell(total_drop)="row">
      <span class="text-danger">{{ row.item.total_drop | currency }}</span>
    </template>
    <template v-slot:cell(selected)="row">
      <b-button-group>
      <!--<div class="custom-control custom-checkbox">
        <input
        type="checkbox"
        class="custom-control-input"
        :id="`checkbox-${row.item.id}`"
        :name="`checkbox-${row.item.id}`"
        :value="row.item.id"
        v-model="selectIds">
        <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
      </div>-->
      <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info" pill>
        <fa :icon="row.detailsShowing ? 'minus' : 'plus'" />
      </b-button>
      </b-button-group>
    </template>
    <template v-slot:cell(phone)="row">
      <a :href="`tel:${row.item.phone}`">{{ row.item.phone }}</a>
    </template>
    <template v-slot:cell(delivery)="row">
      <b-badge variant="danger" pill>{{ row.item.delivery }}</b-badge>
      <b-button @click="logInfo(row.item.id)" size="sm" variant="btn-link" title="Інформація" v-b-modal.modal-log>
        <fa icon="info-circle" />
      </b-button>
      <div v-if="row.item.internet_document">
        <!--<div v-if="hasRole === 'admin'">
          <input @change="setInternetDocument(row.item.id, $event)" type="number" class="form-control form-control-sm" :value="row.item.internet_document">
        </div>-->
          <span v-if="row.item.delivery_method === 'nova-posta'">ТТН: </span>
          <span v-else>ШКІ: </span>
          {{ row.item.internet_document }}
          <b-button-group v-if="hasRole === 'admin'">
            <b-button @click="infoTtn(row.item, $event.target)" size="sm" variant="outline-primary"><fa icon="eye" /></b-button>
            <b-button @click="info(row.item, $event.target)" size="sm" variant="outline-warning"><fa icon="pencil-alt"/></b-button>
          </b-button-group>
      </div>
    </template>
    <template v-slot:cell(payment_status_id)="row">
      <select name="status" class="form-control form-control-sm" v-model="row.item.payment_status_id" @change="changePaymentStatus(row.item.id, $event)">
        <option v-for="(item, index) in paymentStatuses" :key="index" :value="item.id">{{ item.name }}</option>
      </select>
      <!--<b-form-input
      v-if="row.item.payment_status_id === '43jxEYgLPQZOpnel5aKBzyVXvAWJ29Nl' || row.item.payment_status_id === 'PZWjR7BErm0wMvbmOeYAlO1nqVyD42op'"
      size="sm"
      :placeholder="$t('deposit')"
      v-model="row.item.deposit"
      @change="setDeposit(row.item.id, $event)"></b-form-input>-->
    </template>
    <template v-slot:cell(order_status)="row">
      <select name="status" class="form-control form-control-sm" v-model="row.item.order_status" @change="changeOrderStatus(row.item.id, $event)">
        <option v-for="(item, index) in orderStatuses" :key="index" :value="item.id">{{ item.name }}</option>
      </select>
    </template>
    <template v-slot:cell(actions)="row">
        <b-button-group>
          <!-- checing ttn number -->
          <b-button v-if="row.item.internet_document" @click="trackDeliveryStatus(row.item.internet_document)" size="sm" title="Оновити статус доставки"><fa icon="sync" /></b-button>
          <router-link :to="{ name: 'orders.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
          <b-button size="sm" variant="danger" @click="removeItem(row.item.id)">
            <fa icon="trash" />
          </b-button>
        </b-button-group>
    </template>
    <template v-slot:row-details="row">
      <SubTableOrderProducts  v-if="row.item.products.data" :items="row.item.products.data" :order-id="row.item.id" />
    </template>
  </b-table>
  <!-- Pagination -->
  <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
  <!-- check modal -->
  <b-modal :id="checkModal.id" hide-footer :title="checkModal.title">
    <div v-html="checkModal.body"></div>
  </b-modal>
  <b-modal id="internet-document-modal" size="sm" hide-footer>
    <form @submit.prevent="setInternetDocument" @keydown="form.onKeydown($event)" class="create-form">
      <div class="form-group">
        <label for="internet_document">{{ $t('internet_document') }}</label>
        <input type="text" name="internet_document" class="form-control form-control-sm" v-model="form.internet_document" :class="{ 'is-invalid': form.errors.has('internet_document') }" :placeholder="$t('internet_document')">
        <has-error :form="form" field="internet_document" />
      </div>
      <button type="submit" class="btn btn-success btn-sm" :disabled="form.busy">{{ $t('change') }}</button>
    </form>
  </b-modal>
  <b-modal id="info-internet-document-modal" size="md" hide-footer :title="infoInternetDocumentModal.title">
    <ul v-if="infoInternetDocumentModal.content" class="list-unstyled">
      <li v-for="(item, index) in infoInternetDocumentModal.content" :key="index">
        <p v-for="(val, key) in item" :key="key">
          <span v-if="val">
            <strong>{{ key }}</strong>: <b-badge>{{ val }}</b-badge>
          </span>
        </p>
      </li>
    </ul>
  </b-modal>
  <b-modal id="modal-log" hide-footer>
      <template #modal-title>
        {{ $t('order_log_title') }}
      </template>
      <div class="table-responsive">
        <table v-if="logs.length > 0" class="table">
          <thead>
            <tr>
              <th>{{ $t('created') }}</th>
              <th>{{ $t('message') }}</th>
              <th>{{ $t('user') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in logs" :key="index">
              <td>{{ item.created_at }}</td>
              <td>
                <p class="text-success" :class="{ 'text-danger': item.status === 0 }">{{ item.message }}</p>
              </td>
              <td>{{ item.user }}</td>
            </tr>
          </tbody>
        </table>
        <b-alert v-else variant="info" show>{{ $t('empty')}}</b-alert>
      </div>
    </b-modal>
    <b-modal id="modal-register" size="lg" hide-footer>
      <template #modal-title>
        Робота з реєстром
      </template>
      <div class="row">
        <div class="col-sm-10">
            <table class="table">
              <thead>
                <tr>
                  <th>Дата</th>
                  <th>№ Реєстру</th>
                  <th>К-сть</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="register">
                <tr v-for="(item, index) in register" :key="index">
                  <td>{{ item.DateTime }}</td>
                  <td>{{ item.Number }}</td>
                  <td>{{ item.Count }}</td>
                  <th>
                    <b-button size="sm" variant="light"><fa icon="print" /></b-button>
                  </th>
                  <td>
                    <b-button size="sm" variant="outline-danger"><fa icon="trash"/></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </b-modal>
</b-card>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import SubTableOrderProducts from './product/subTableInfo'
import Swal from 'sweetalert2'
import Form from 'vform'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('orders') }
    },
    components: {
      SubTableOrderProducts
    },
    data: () => ({
      form: new Form({
        id: "",
        internet_document: "",
      }),
      filters: {
        text: {
          order_num: '',
          created_at: '',
          customer: '',
          phone: '',
          total: 0,
          total_drop: 0
        },
        select: {
          payment_status_id: ''
        }
      },
      internetDocumentModal: {
        id: 'internet-document-modal',
        title: '',
        content: ''
      },
      infoInternetDocumentModal: {
        id: 'info-internet-document-modal',
        title: '',
        content: []
      },
      items: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'order_num',
          label: 'Н/з',
          typesort: 'text'
        },
        {
          key: 'created_at',
          label: 'Дата',
          typesort: 'text'
        },
        {
          key: 'customer',
          label: 'Клієнт',
          typesort: 'text'
        },
        {
          key: 'phone',
          label: 'Телефон',
          typesort: 'text'
        },
        {
          key: 'delivery',
          label: 'Доставка',
          typesort: 'select',
          class: 'cellWidth'
        },
        {
          key: 'city',
          label: 'Місто',
          typesort: 'text'
        },
        {
          key: 'payment_type',
          label: 'Оплата',
          typesort: 'select'
        },
        {
          key: 'total',
          label: 'Сумма',
          typefield: 'textfield',
          typesort: 'text',
          class: 'text-center'
        },
        {
          key: 'total_drop',
          label: 'Сумма Дроп',
          typesort: 'text'
        },
        {
          key: 'payment_status_id',
          label: 'Статус оплати',
          typesort: 'select'
        },
        {
          key: 'delivery_status',
          label: 'Статус дост.'
        },
        {
          key: 'order_status',
          label: 'Статус зам.',
          typesort: 'select'
        },
        {
          key: 'actions',
          label: 'Дії'
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      infoModal: {
        id: 'info-modal',
        title: 'Зміна ТТН',
        order: ''
      },
      checkModal: {
        id: 'money-check-modal',
        title: 'Інформація про чек',
        body: null
      },
      selected: [],
      allSelected: false,
      selectIds: [],
      search: '',
      search_sku: '',
      search_color: '',
      urlParams: {},
      register: null,
      logs: []
    }),
    mounted () {
        this.fetchData().catch(error => {
            console.log(error)
        })
        this.setStatuses()
        if (this.deliveries === null || this.payments === null) {
          this.setDeliveries()
          this.setPayments()
        }
    },
    computed: {
      ...mapGetters({
        deliveries: 'order/deliveries',
        payments: 'order/payments',
        statuses: 'order/statuses',
        hasRole: 'auth/hasRole'
      }),
      // eslint-disable-next-line vue/return-in-computed-property
      orderStatuses () {
        if (this.statuses) {
          return this.statuses.filter(s => s.type === 'order')
        }
      },
      // eslint-disable-next-line vue/return-in-computed-property
      paymentStatuses () {
        if (this.statuses) {
          return this.statuses.filter(s => s.type === 'payment')
        }
      }
    },
    methods: {
      ...mapActions({
        setDeliveries: 'order/setDeliveries',
        setPayments: 'order/setPayments',
        setStatuses: 'order/setStatuses'
      }),
      async getCheck (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'checkbox/check/' + id).then(resp => {
          if (resp && resp.data) {
            this.checkModal.body = resp.data
            this.$root.$emit('bv::show::modal', this.checkModal.id)
            this.$root.$refs.loading.finish()
          }
        })
      },
      async setInternetDocument () {
        const id = this.form.id
        const value = this.form.internet_document
        const newInternetDocumentNumber = Number.parseInt(value)
        if (typeof newInternetDocumentNumber === 'number') {
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          this.$root.$refs.loading.start()
          await axios.post(apiUrl + 'orders/change-internet-document', { id: id, value: value }).then(resp => {
            if (resp.data.status) {
              Swal.fire({
                title: 'Інфо!',
                text: 'ТТН було змінено!',
                icon: 'info',
                confirmButtonText: 'Ok'
              })
              this.$root.$emit('bv::hide::modal', this.internetDocumentModal.id)
              this.$root.$refs.loading.finish()
              this.fetchData()
            } else {
              Swal.fire({
                title: 'Помилка!',
                text: resp.data.msg,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          })
        } else {
          Swal.fire({
            title: 'Помилка!',
            text: 'Значення яке задається повинно бути числом!',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      },
      /*
      async setDeposit (orderId, val) {
        if (val.length > 1) {
          this.$root.$refs.loading.start()
          const summ = Number.parseFloat(val)
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.post(apiUrl + 'orders/deposit', { summ: summ, id: orderId })
          this.$root.$refs.loading.finish()
        }
      },
      */
      clearFilter () {
        this.urlParams = {}
        this.fetchData()
      },
      searchByText (key, val) {
        this.urlParams[key] = val
        this.fetchData()
      },
      searchBySelect (key, e) {
        const value = e.target.value
        this.urlParams[key] = value
        this.fetchData()
      },
      buildQuery () {
        return Object.entries(this.urlParams).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
      },
      async logInfo (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'packaging/logs/' + id)
        this.logs = data
        this.$root.$refs.loading.finish()
      },
      async fetchData () {
        const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'orders?page=' + this.currentPage + params)
        this.items = data.data
        this.currentPage = data.meta.pagination.current_page
        this.perPage = data.meta.pagination.per_page
        this.totalItems = data.meta.pagination.total
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'orders/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      exportOrders (e) {
        alert('Action export file..')
      },
      searching () {
        this.fetchData()
      },
      // infoTtn (item, index, btn) {
      //   this.infoModal.title = `Інформація про замовлення №: ${item.order_num}`
      //   this.infoModal.order = item
      //   this.$root.$emit('bv::show::modal', this.infoModal.id, btn)
      // },
      resetInfoModal () {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      selectAll () {
        this.selectIds = []
        if (!this.allSelected) {
          for (const item in this.items) {
            this.selectIds.push(this.items[item].id)
          }
        }
      },
      async changePaymentStatus (orderId, e) {
        const id = e.target.value
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/payment', { order: orderId, status: id })
        this.$root.$refs.loading.finish()
      },
      async changeOrderStatus (orderId, e) {
        const id = e.target.value
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/status', { order: orderId, status: id })
        this.$root.$refs.loading.finish()
      },
      info (item, btn) {
        this.form.fill(item)
        this.$root.$emit('bv::show::modal', this.internetDocumentModal.id, btn)
      },
      async infoTtn (item, btn) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/internet-document-info', { ttn: item.internet_document }).then(resp => {
          if (resp.data.status) {
            if (typeof resp.data.result === 'string') {
              const arr = JSON.parse(resp.data.result)
              this.infoInternetDocumentModal.content = [arr]
            } else {
              this.infoInternetDocumentModal.content = resp.data.result
            }
            this.infoInternetDocumentModal.title = 'Інформація про ТТН: ' + item.internet_document
            this.$root.$emit('bv::show::modal', this.infoInternetDocumentModal.id, btn)
          }
        })
      },
      async trackDeliveryStatus (ttn) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/tackInternetDocument', { ttn: ttn }).then(resp => {
          if (resp.data.status) {
            this.fetchData()
            this.$root.$refs.loading.finish()
          }
        })
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
<style>
.table {
  font-size: 0.9rem!important;
}
.table th, .table td {
  padding: 0.30rem;
}
.table .cellWidth {
  width: 240px;
}
.pointer {
  cursor: pointer;
}
</style>
